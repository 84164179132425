/**
 * This module contains global configuration values that are not different per environment.
 * It is not meant to be used for environment-specific values like Authentication, APIs, client secrets, etc. as
 * values here will be included in all production bundles unchanged.
 */

const identityUserUrl = 'identity/v1/Users';
const billingUrl = 'rest/v1';
const extAdminUrl = 'ext-admin/rest';
const mtgServiceUrl = 'rest/2';
const mtgUserUrl ='meeting/rest/2';

export default {
  routes: {
    products: () =>'/products',
  },
  noKey: {
    error: () => '/error',
  },
  endpoints: {
    me: `${identityUserUrl}/me`,
    meWithUniqueParam: `${identityUserUrl}/me?u=${new Date().valueOf()}`,
    avatarUrls: `${identityUserUrl}/me/avatarurls`,
    billingAccountMe: `${billingUrl}/me`,
    subscriptionUrl: (key) => `${billingUrl}/billingaccounts/${key}/subscriptiongroups`,
    paymentUrl: (key) => `${billingUrl}/billingaccounts/${key}/paymentmethods`,
    extAdminMe: `${extAdminUrl}/me`,
    adminMe: `${extAdminUrl}/me?includeAdmins=true&includeInvitation=true`,
    mtgServiceSettings: `${mtgServiceUrl}/settings`,
    mtgUserSetting: (userKey) => `${mtgUserUrl}/users/${userKey}/settings`,
    mtgConferences : (accountKey) => `accounts/${accountKey}/conferences/`,
    meGrapQlRequestWihQuery:(queryString) =>  `me/graphql?query=${queryString}`,
    meGraphQlRequest: `me/graphql`,
  },
  storage: {
    oauth: 'auth_token',
  },
  scimSchemas: {
    core: 'urn:scim:schemas:core:1.0',
    enterprise: 'urn:scim:schemas:extension:enterprise:1.0',
    getGo: 'urn:scim:schemas:extension:getgo:1.0',
  },
  supportedLocales: [
    'de_DE',
    'en_US',
    'es_ES',
    'fr_FR',
    'it_IT',
    'zh_CN',
    'ja_JP',
    'ko_KR',
    'pt_BR',
  ],
  logMeInProducts: {
    g2ars: 'GoToAssist Remote Support',
    g2asd: 'GoToAssist Service Desk',
    g2aseeit: 'GoToAssist Seeit',
    g2m: 'GoToMeeting',
    g2mfree: 'GoToMeeting Free',
    g2t: 'GoToTraining',
    g2w: 'GoToWebinar',
    g2p: 'GoToMyPc',
    openvoice: 'OpenVoice',
    jive: 'jive',
    hvoice: 'HVoice',
    gotoconnect: 'GoToConnect',
    bold360: 'Bold360',
    resolve: 'Resolve',
    grasshopper: 'Grasshopper',
  },
  supportExperiences: {
    DEFAULT: 'DEFAULT',
    HIDE: 'HIDE',
    JIVE_ONLY: 'JIVE_ONLY',
  },
  alertTypes: {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'marketing',
    DANGER: 'danger'
  },
};
