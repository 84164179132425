import React from 'react';
import appconfig from 'config/appconfig';
import {
  GotoAssistIcon,
  SeeItIcon,
  GotoIcon,
  OpenVoiceIcon,
  GrasshopperMarkIcon
} from '@getgo/chameleon-icons/react';
import {
  MeetingStaticPictogram ,
  PresentationStaticPictogram,
  WebinarStaticPictogram,
  PhoneConversationStaticPictogram,
 } from '@getgo/chameleon-pictograms/react';
import ResolveEmblem from 'static/images/svg/resolve-emblem.svg';

// Product settings and name references
const navProductSpecs = {
  g2ars: { class: <GotoAssistIcon color="type-color-default" />, product: appconfig.logMeInProducts.g2ars, productUrlRef: 'GoToAssist' },
  g2asd: { class: <GotoAssistIcon color="type-color-default" />, product: appconfig.logMeInProducts.g2asd, productUrlRef: 'Service Desk' },
  g2aseeit: { class: <SeeItIcon color="type-color-default" />, product: appconfig.logMeInProducts.g2aseeit, productUrlRef: 'Seeit' },
  g2m: { class: <MeetingStaticPictogram color="type-color-default" />, product: appconfig.logMeInProducts.g2m, productUrlRef: 'GoToMeeting' },
  g2mApp: { class: <MeetingStaticPictogram color="type-color-default" />, product: appconfig.logMeInProducts.g2m, productUrlRef: 'GoToMeetingApp' },
  g2mfree: { class: <MeetingStaticPictogram color="type-color-default" />, product: appconfig.logMeInProducts.g2mfree, productUrlRef: 'GoToMeeting' },
  g2mfreeApp: { class: <MeetingStaticPictogram color="type-color-default" />, product: appconfig.logMeInProducts.g2mfree, productUrlRef: 'GoToMeetingApp' },
  g2t: { class: <PresentationStaticPictogram color="type-color-default" />, product: appconfig.logMeInProducts.g2t, productUrlRef: 'GoToTraining' },
  g2w: { class: <WebinarStaticPictogram color="type-color-default" />, product: appconfig.logMeInProducts.g2w, productUrlRef: 'GoToWebinar' },
  g2p: { class: <GotoIcon color="type-color-default" />, product: appconfig.logMeInProducts.g2p, productUrlRef: 'GoToMyPc' },
  openvoice: {class: <OpenVoiceIcon color="type-color-default" />, product: appconfig.logMeInProducts.openvoice, productUrlRef: 'OpenVoice' },
  jive: { class: <GotoIcon color="type-color-default" />, product: appconfig.logMeInProducts.jive, productUrlRef: 'Jive' },
  hvoice: { class: <GotoIcon color="type-color-default" />, product: appconfig.logMeInProducts.hvoice, productUrlRef: 'Jive' },
  gotoconnect: { class: <PhoneConversationStaticPictogram color="type-color-default" />, product: appconfig.logMeInProducts.gotoconnect, productUrlRef: 'GoToConnect' },
  bold360: { class: <GotoIcon color="type-color-default" />, product: appconfig.logMeInProducts.bold360, productUrlRef: 'Bold360_url' },
  gotoresolve: { class: <img alt="resolve-emblem-logo" style={{height:"20px" ,width:"20px"}} src={ResolveEmblem} />, product: appconfig.logMeInProducts.resolve, productUrlRef: 'Resolve' },
  grasshopper: { class: <GrasshopperMarkIcon color="type-color-default" />, product: appconfig.logMeInProducts.grasshopper, productUrlRef: 'GrassHopper' },
};
export default navProductSpecs;
