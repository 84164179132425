/* Static data for all the products for product container */

export const prodSpecs = {
    g2a: {
     class: 'gotoassist',
     prodBold: 'GoToAssist',
     prodLight: '',
     linkText: 'Launch GoToAssist',
     product: 'GoToAssist'
    },
    g2ars: {
     class: 'gotoassist',
     prodBold: 'GoTo',
     prodLight: 'Assist',
     subProd: 'Remote Support',
     linkText: 'Launch Remote Support',
     product: 'Remote Support'
    },
    g2asd: {
     class: 'gotoassist',
     prodBold: 'GoTo',
     prodLight: 'Assist',
     subProd: 'Service Desk',
     linkText: 'Launch Service Desk',
     product: 'Service Desk'
    },
    g2aseeit: {
     class: 'seeit',
     prodBold: 'GoTo',
     prodLight: 'Assist Seeit',
     linkText: 'Launch GoTo Assist',
     product: 'Seeit'
    },
    g2m: {
     class: 'gotomeeting',
     prodBold: 'GoTo',
     prodLight: 'Meeting',
     linkText: 'My meetings',
     product: 'GoToMeeting',
     announcement: true,
     announcementLinkText: true,
     startDate: '10/15/2019',
     endDate: '3/1/2050'
    },
    g2w: {
     class: 'gotowebinar',
     prodBold: 'GoTo',
     prodLight: 'Webinar',
     linkText: 'My webinars',
     product: 'GoToWebinar'
    },
    g2t: {
     class: 'gototraining',
     prodBold: 'GoTo',
     prodLight: 'Training',
     linkText: 'My trainings',
     product: 'GoToTraining',
    },
    openvoice: {
     class: 'openvoice',
     prodBold: 'OpenVoice',
     prodLight: '',
     linkText: 'Launch OpenVoice',
     product: 'OpenVoice'
    },
    grasshopper: {
     class: 'grasshopper',
     prodBold: 'Grasshopper',
     prodLight: '',
     linkText: 'Launch Grasshopper',
     product: 'GrassHopper'
    },
    g2p: {
     class: 'gotomypc',
     prodBold: 'GoToMyPC',
     prodLight: '',
     linkText: 'Launch GoToMyPC',
     product: 'GoToMyPc'
    },
    jive: {
     class: 'jive',
     prodBold: 'Jive',
     prodLight: '',
     linkText: 'Launch Jive',
     product: 'Jive'
    },
    gotoconnect: {
     class: 'gotoconnect',
     prodBold: 'GoTo ',
     prodLight: 'Connect',
     linkText: 'Launch GoTo Connect',
     product: 'GoToConnect'
    },
    gotoresolve: {
     class: 'gotoresolve',
     prodBold: 'Resolve',
     prodLight: '',
     linkText: 'Launch Resolve',
     product: 'Resolve'
    },
    partner: {
     class: 'gotopartner',
     prodBold: 'GoTo Admin ',
     prodLight: 'Partner Edition',
     linkText: 'Launch GoTo Admin Partner Edition',
     product: 'GoTo Admin Partner Edition'
    },
    globalAnnouncement: {
     announcement: false,
     startDate: '05/20/2019',
     endDate: '7/31/2019'
    }
};
export const prodArray = ['g2m', 'grasshopper', 'openvoice', 'g2w', 'g2p', 'g2t', 'g2a', 'g2aseeit', 'g2asd', 'g2ars', 'jive', 'gotoconnect', 'gotoresolve', 'partner'];
